import { useEffect, useState } from "react";
import { Amplify, Auth } from "aws-amplify";
// import { CognitoUser } from "amazon-cognito-identity-js";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import * as Styled from "./style";

// TODO: redirect if login successful DONE but how should we logout now?
Amplify.configure({
  Auth: {
    userPoolId: "us-east-1_E0Du4KFgW",
    region: "us-east-1",
    userPoolWebClientId: "3klhqa5r4elljevdlrq7n0vfv0",
    oauth: {
      domain: "https://hades.auth.us-east-1.amazoncognito.com",
      scope: ["jobs", "md_run", "md_params"],
    },
  },
});

export async function isConnected() {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    return false;
  }
}

async function signIn(userName: string, password: string) {
  return await Auth.signIn(userName, password);
}

export function signOut() {
  return Auth.signOut();
}

// async function completePassword(user: CognitoUser, password: string) {
//   Auth.completeNewPassword(user, password)
//     .then((user: CognitoUser) => {
//       console.log(user);
//     })
//     .catch((e: Error) => {
//       console.log(e);
//     });
// }

// async function getToken() {
//   let data = await Auth.currentSession();
//   console.log(data);
//   // return data.getIdToken().getJwtToken();
// }

export default function LoginPage() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isConnectedValue, setIsConnectedValue] = useState(false);

  useEffect(() => {
    isConnected().then((val) => setIsConnectedValue(val));
  }, []);
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      {isConnectedValue ? (
        <>
          {/* <button onClick={getToken} >log token</button> */}
          {/* <button onClick={() => user && completePassword(user, password)}>update password</button> */}
          {/* <Styled.GlobalStyle/>
            <Styled.Wrapper>
              <Styled.Button onClick={() => {signOut().then(() => IsConnectedValue(false))}}>Sign me out</Styled.Button>
            </Styled.Wrapper> */}
          {(window.location.href = "/")}
        </>
      ) : (
        <>
          <Styled.GlobalStyle />
          <Styled.Wrapper>
            <Styled.Form>
              <Styled.Input
                type="text"
                placeholder="Username"
                onChange={(e) => setUserName(e.target.value)}
                value={userName}
              />
              <Styled.PasswordBox>
                <Styled.Input
                  type={passwordShown ? "text" : "password"}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <Styled.ShowButton onClick={togglePassword}>
                  {passwordShown === false ? (
                    <BsFillEyeFill background-color="black" />
                  ) : (
                    <BsFillEyeSlashFill />
                  )}
                </Styled.ShowButton>
              </Styled.PasswordBox>
              <Styled.Button
                onClick={() => {
                  signIn(userName, password).then((user) => {
                    setIsConnectedValue(true);
                  });
                }}
              >
                Sign me in
              </Styled.Button>
            </Styled.Form>
          </Styled.Wrapper>
        </>
      )}
    </>
  );
}
