import _ from "lodash";
import Plot from "react-plotly.js";

function Plot2D({ data, name }: { data: number[]; name: string }) {
  var layout = {
    title: name,
    autosize: false,
    width: 500,
    height: 500,
    margin: {
      l: 65,
      r: 50,
      b: 65,
      t: 90,
    },
  };
  const chuncked_data = _.chunk(data, 40);
  console.log(chuncked_data);
  return (
    <Plot
      data={[
        {
          y: data,
          type: "scatter",
        },
      ]}
      layout={layout}
    />
  );
}

export default Plot2D;
