import { useState } from "react";
import {
  BiFileBlank,
  BiPlay,
  BiPlusCircle,
  BiSave,
  BiUpload,
} from "react-icons/bi";
import { FlameApi } from "../../../api";
import Header from "../../../components/header/Header";

import ProgressBar from "@ramonak/react-progress-bar";
import { MathJaxContext } from "better-react-mathjax";
import _ from "lodash";
import Selector from "../../../components/Selector";
import UploadBox from "../../../components/UploadComponents";
import RenderedChemicalReaction from "./ChemicalReaction";
import Plot2D from "./plot";
import * as Styled from "./style";

const e = (s: TemplateStringsArray) => "\\(" + r(s) + "\\)";
const r = String.raw;

const eq = {
  mass: e`{\dot m}\,\partial_x Y_1 - \frac{\lambda}{C_p}  Le^{-1}\partial^2_x Y_1 = -{A}TY_1`,
  temperature: e`{\dot m}\,\partial_x T - \frac{\lambda}{C_p} \partial^2_x T = \Delta_h\tilde{A}TY_1`,
};

const nop = () => {};

const api = new FlameApi();

function FlameSimulation() {
  const [f, setF] = useState<number[]>([]);
  const [g, setG] = useState<number[]>([]);
  const [ran, setRan] = useState(false);
  const [apiFailed, setApiFailed] = useState(false);
  const [equation, setEquation] = useState("Select equation");
  const [progress, setProgress] = useState(0);
  const [dimension, setDimension] = useState("Select dimension");
  const [viscosity, setViscosity] = useState("Select viscosity");
  const [acceleration, setAcceleration] = useState(false);
  const chemicalReactions = [
    {
      reactants: [
        { formula: "CH4", coefficient: 1, initialQuantity: 10 },
        { formula: "02", coefficient: 2, initialQuantity: 20 },
      ],
      products: [
        { formula: "CO2", coefficient: 1 },
        { formula: "H2O", coefficient: 2 },
      ],
    },
  ];

  let progressInterval: NodeJS.Timer;

  let idToken: any;
  for (const [key, value] of Object.entries(localStorage)) {
    if (key?.includes("idToken")) {
      idToken = value;
    }
  }

  function startSimulation() {
    const duration = 4; /* seconds */
    progressInterval = setInterval(
      () => setProgress((p) => p + 1),
      duration * 7
    );
    setTimeout(
      () =>
        api.solveFlame(idToken).then((resp) => {
          if (resp.status === 200) {
            const [f_v, g_v] = _.chunk(resp.data, 39);
            setF(f_v);
            setG(g_v);
            setRan(true);
            setProgress(0);
            clearInterval(progressInterval);
          } else {
            setApiFailed(true);
          }
        }),
      duration * 1000
    );
  }

  return (
    <>
      <Header />
      <Styled.Container>
        <MathJaxContext>
          <Styled.Side>
            <Styled.ProjectName>
              <Styled.UnnamedProject>Unnamed project</Styled.UnnamedProject>
              <Styled.RenameButton title="Rename project" onClick={() => {}}>
                <Styled.RenameIcon />
              </Styled.RenameButton>
            </Styled.ProjectName>
            <div id="Project">
              <Styled.Project>
                <Styled.RunButton
                  title="Run project"
                  onClick={() => startSimulation()}
                >
                  <BiPlay />
                </Styled.RunButton>
                <Styled.Button title="Save project">
                  <BiSave />
                </Styled.Button>
                <Styled.Button title="Load project">
                  <BiUpload />
                </Styled.Button>
                <Styled.Button title="New project">
                  <BiFileBlank />
                </Styled.Button>
              </Styled.Project>
            </div>
            <Styled.SideInput>
              <Styled.Header>Equation</Styled.Header>
              <Selector
                name="Equation"
                setCurrentValue={setEquation}
                currentValue={equation}
                availableValues={[
                  "Euler combustion",
                  "Laminar Navier-Stockes combustion",
                  "Turbulent Navier-Stockes combustion",
                ]}
                defaultValue={"Select equation"}
              />
            </Styled.SideInput>
            <Styled.SideInput>
              <Styled.Header>Dimensionality</Styled.Header>
              <Selector
                name="Dimensionality"
                setCurrentValue={setDimension}
                currentValue={dimension}
                availableValues={["1D", "2D", "3D"]}
                defaultValue={"Select dimension"}
              />
              <Styled.CInput type="checkbox" id="time" />
              <Styled.CLabel htmlFor="time">Stationary analysis</Styled.CLabel>
            </Styled.SideInput>
            <Styled.SideInput>
              <Styled.Header>Reactants mixing</Styled.Header>
              <Styled.Form>
                <Styled.CInput type="checkbox" name="mixing" id="premixed" />
                <Styled.CLabel htmlFor="premixed">Premixed</Styled.CLabel>
                <br />
                <Styled.CInput type="checkbox" name="mixing" id="partial" />
                <Styled.CLabel htmlFor="partial">
                  Partially premixed
                </Styled.CLabel>
                <br />
                <Styled.CInput type="checkbox" name="mixing" id="counterflow" />
                <Styled.CLabel htmlFor="counterflow">Counterflow</Styled.CLabel>
              </Styled.Form>
            </Styled.SideInput>
            <Styled.SideInput>
              <Styled.Header>Flow properties</Styled.Header>
              <Styled.Form>
                <Styled.CInput
                  type="checkbox"
                  name="flow"
                  id="acceleration"
                  onChange={(e) => setAcceleration(e.target.checked)}
                />
                <Styled.CLabel htmlFor="acceleration">
                  External acceleration
                </Styled.CLabel>
              </Styled.Form>
            </Styled.SideInput>
          </Styled.Side>
          <Styled.Center>
            <Styled.Math>{eq.mass}</Styled.Math>
            <Styled.Math>{eq.temperature}</Styled.Math>
            {progress !== 0 && (
              <ProgressBar
                transitionDuration="100ms"
                width="20rem"
                completed={_.min([progress, 100]) || 0}
              />
            )}
            {ran}
            {!ran && progress === 0 && (
              <Styled.ChemicalReactions>
                <Styled.HeadlessHeader>
                  Chemical reactions
                </Styled.HeadlessHeader>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "80%",
                  }}
                >
                  <div>
                    <BiPlusCircle
                      style={{
                        fontSize: "1.5em",
                        marginRight: ".25em",
                        cursor: "pointer",
                        translate: "0 .25em",
                      }}
                    />
                    Add chemical reaction
                  </div>
                  <div>Initial reactant quantities (mol)</div>
                </div>
                {chemicalReactions.map((reaction) => (
                  <RenderedChemicalReaction reaction={reaction} />
                ))}
              </Styled.ChemicalReactions>
            )}
            {!ran && progress === 0 && (
              <div style={{ display: "flex", gap: "2em", marginTop: "5em" }}>
                <UploadBox name="Upload geometric profile" />
                <UploadBox name="Upload boundary conditions file" />
              </div>
            )}
            {ran && !apiFailed && (
              <Styled.Results>
                <Styled.ResultGraphs>
                  <Plot2D data={f} name="mass fraction" />
                  <Plot2D data={g} name="temperature" />
                </Styled.ResultGraphs>
                <div style={{ alignContent: "center" }}>
                  <img
                    style={{ width: "30rem" }}
                    src="images/flame_diagram.gif"
                    alt="diagram of the flame"
                  />
                </div>
              </Styled.Results>
            )}
          </Styled.Center>
          {(equation.includes("Navier") || acceleration) && (
            <>
              <Styled.Side>
                {equation.includes("Navier") && (
                  <Styled.SideInput>
                    <Styled.Header>Viscosity stress tensor</Styled.Header>
                    <Selector
                      name="Viscosity"
                      setCurrentValue={setViscosity}
                      currentValue={viscosity}
                      availableValues={[]}
                      defaultValue={"Select viscosity"}
                    />
                  </Styled.SideInput>
                )}
                {acceleration && (
                  <Styled.SideInput>
                    <Styled.Header>Parameters</Styled.Header>
                    <p>External acceleration vector</p>
                    <Styled.ParamInput>
                      <Styled.Label htmlFor="x">
                        <Styled.Math>{e`x: `}</Styled.Math>
                      </Styled.Label>
                      <Styled.FInput
                        name="x"
                        coord="x"
                        location={{ x: 1 }}
                        disabled={false}
                        setter={nop}
                      />
                    </Styled.ParamInput>
                    <Styled.ParamInput>
                      <Styled.Label htmlFor="y">
                        <Styled.Math>{e`y: `}</Styled.Math>
                      </Styled.Label>
                      <Styled.FInput
                        name="y"
                        coord="y"
                        location={{ y: 1 }}
                        disabled={false}
                        setter={nop}
                      />
                    </Styled.ParamInput>
                    <Styled.ParamInput>
                      <Styled.Label htmlFor="z">
                        <Styled.Math>{e`z: `}</Styled.Math>
                      </Styled.Label>
                      <Styled.FInput
                        name="z"
                        coord="z"
                        location={{ z: 1 }}
                        disabled={false}
                        setter={nop}
                      />
                    </Styled.ParamInput>
                  </Styled.SideInput>
                )}
              </Styled.Side>
            </>
          )}
        </MathJaxContext>
      </Styled.Container>
    </>
  );
}

export default FlameSimulation;
