import ProgressBar from "@ramonak/react-progress-bar";
import { MathJaxContext } from "better-react-mathjax";
import _ from "lodash";
import { useState } from "react";
import { BiFileBlank, BiPlay, BiSave, BiUpload } from "react-icons/bi";
import { CFDApi } from "../../../api";
import Header from "../../../components/header/Header";
import Selector from "../../../components/Selector";
import UploadBox from "../../../components/UploadComponents";
import Plot3D from "./plot";
import * as Styled from "./style";

const e = (s: TemplateStringsArray) => "\\(" + r(s) + "\\)";
const r = String.raw;

const eq = {
  continuity: e`\frac{\partial \rho }{\partial t} + \mathbf{\nabla}\cdot(\rho \mathbf{u})=0`,
  momentum: e`\frac{\partial(\rho\mathbf{u})}{\partial t} + \mathbf{\nabla}\cdot\left(\rho \mathbf{u} \otimes \mathbf{u} \right) + \mathbf{\nabla}p = \mathbf{0}`,
  energy: e`\frac{\partial(\rho E)}{\partial t} + \mathbf{\nabla}\cdot(\rho E\mathbf{u}) + p\mathbf{\nabla}\cdot\mathbf{u} = 0`,
  damping: r`-\frac{\mu}{(1+t)^{\lambda}} \rho\mathbf{u}`,
  "1D": r`\frac{\partial}{\partial x}`,
  nDDiv: r`\mathbf{\nabla}\cdot`,
  nDGrad: r`\mathbf{\nabla}`,
  pressure: r` + \mathbf{\nabla}p`,
  velocity1DProduct: r`\mathbf{u}^2`,
  velocityNDProduct: r`\mathbf{u} \otimes \mathbf{u}`,
  nsMomentum: r`\mathbf{\nabla}\cdot\Sigma`,
  nsEnergy: r`\mathbf{\nabla}\cdot(\Sigma\cdot\mathbf{u}) - \mathbf{\nabla}\cdot\mathbf{Q}`,
  timeVariationPatern: /\\frac\{.*\}\{\\partial t\}( \+)?/g,
};

function ContinuityEquation({
  dimension,
  equilibrium,
}: {
  dimension: string;
  equilibrium: boolean;
}) {
  return (
    <>
      {!equilibrium && dimension === "1D" && (
        <Styled.Math>
          {eq.continuity
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])}
        </Styled.Math>
      )}
      {!equilibrium && dimension !== "1D" && (
        <Styled.Math>{eq.continuity}</Styled.Math>
      )}
      {equilibrium && dimension === "1D" && (
        <Styled.Math>
          {eq.continuity
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {equilibrium && dimension !== "1D" && (
        <Styled.Math>
          {eq.continuity.replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
    </>
  );
}

function MomentumEquation({
  isopressure,
  equation,
  dimension,
  equilibrium,
}: {
  isopressure: boolean;
  equation: string;
  dimension: string;
  equilibrium: boolean;
}) {
  const d = dimension === "1D";
  const t = equation.includes("Damped")
    ? 1
    : equation.includes("Navier")
    ? 2
    : 0;
  const p = isopressure;
  const e = equilibrium;

  return (
    <>
      {e && !d && t === 0 && !p && <Styled.Math>{eq.momentum}</Styled.Math>}
      {e && !d && t === 0 && p && (
        <Styled.Math>
          {eq.momentum
            .replace(eq.pressure, "")
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && !d && t === 1 && !p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.damping)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && !d && t === 1 && p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.damping)
            .replace(eq.pressure, "")
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && !d && t === 2 && !p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.nsMomentum)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && !d && t === 2 && p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.nsMomentum)
            .replace(eq.pressure, "")
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && d && t === 0 && !p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && d && t === 0 && p && (
        <Styled.Math>
          {eq.momentum
            .replace(eq.pressure, "")
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && d && t === 1 && !p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.damping)
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && d && t === 1 && p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.damping)
            .replace(eq.pressure, "")
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && d && t === 2 && !p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.nsMomentum)
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {e && d && t === 2 && p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.nsMomentum)
            .replace(eq.pressure, "")
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {!e && !d && t === 0 && !p && <Styled.Math>{eq.momentum}</Styled.Math>}
      {!e && !d && t === 0 && p && (
        <Styled.Math>{eq.momentum.replace(eq.pressure, "")}</Styled.Math>
      )}
      {!e && !d && t === 1 && !p && (
        <Styled.Math>{eq.momentum.replaceAll("0", eq.damping)}</Styled.Math>
      )}
      {!e && !d && t === 1 && p && (
        <Styled.Math>
          {eq.momentum.replaceAll("0", eq.damping).replace(eq.pressure, "")}
        </Styled.Math>
      )}
      {!e && !d && t === 2 && !p && (
        <Styled.Math>{eq.momentum.replaceAll("0", eq.nsMomentum)}</Styled.Math>
      )}
      {!e && !d && t === 2 && p && (
        <Styled.Math>
          {eq.momentum.replaceAll("0", eq.nsMomentum).replace(eq.pressure, "")}
        </Styled.Math>
      )}
      {!e && d && t === 0 && !p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)}
        </Styled.Math>
      )}
      {!e && d && t === 0 && p && (
        <Styled.Math>
          {eq.momentum
            .replace(eq.pressure, "")
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)}
        </Styled.Math>
      )}
      {!e && d && t === 1 && !p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.damping)
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)}
        </Styled.Math>
      )}
      {!e && d && t === 1 && p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.damping)
            .replace(eq.pressure, "")
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)}
        </Styled.Math>
      )}
      {!e && d && t === 2 && !p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.nsMomentum)
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)}
        </Styled.Math>
      )}
      {!e && d && t === 2 && p && (
        <Styled.Math>
          {eq.momentum
            .replaceAll("0", eq.nsMomentum)
            .replace(eq.pressure, "")
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.velocityNDProduct, eq.velocity1DProduct)}
        </Styled.Math>
      )}
    </>
  );
}

function EnergyEquation({
  dimension,
  equation,
  equilibrium,
}: {
  dimension: string;
  equation: string;
  equilibrium: boolean;
}) {
  return (
    <>
      {!equilibrium && dimension === "1D" && equation.includes("Navier") && (
        <Styled.Math>
          {eq.energy
            .replace("0", eq.nsEnergy)
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])}
        </Styled.Math>
      )}
      {!equilibrium && dimension === "1D" && !equation.includes("Navier") && (
        <Styled.Math>
          {eq.energy
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])}
        </Styled.Math>
      )}
      {!equilibrium && dimension !== "1D" && equation.includes("Navier") && (
        <Styled.Math>{eq.energy.replace("0", eq.nsEnergy)}</Styled.Math>
      )}
      {!equilibrium && dimension !== "1D" && !equation.includes("Navier") && (
        <Styled.Math>{eq.energy}</Styled.Math>
      )}
      {equilibrium && dimension === "1D" && equation.includes("Navier") && (
        <Styled.Math>
          {eq.energy
            .replace("0", eq.nsEnergy)
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {equilibrium && dimension === "1D" && !equation.includes("Navier") && (
        <Styled.Math>
          {eq.energy
            .replaceAll(eq.nDDiv, eq["1D"])
            .replaceAll(eq.nDGrad, eq["1D"])
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {equilibrium && dimension !== "1D" && equation.includes("Navier") && (
        <Styled.Math>
          {eq.energy
            .replace("0", eq.nsEnergy)
            .replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
      {equilibrium && dimension !== "1D" && !equation.includes("Navier") && (
        <Styled.Math>
          {eq.energy.replace(eq.timeVariationPatern, "")}
        </Styled.Math>
      )}
    </>
  );
}

const api = new CFDApi();

function CfdSimulation() {
  const [f, setF] = useState<number[]>([]);
  const [g, setG] = useState<number[]>([]);
  const [ran, setRan] = useState(false);
  const [apiFailed, setApiFailed] = useState(false);
  const [equation, setEquation] = useState("Select equation");
  const [dimension, setDimension] = useState("Select dimension");
  const [viscosity, setViscosity] = useState("Select viscosity");
  const [isothermal, setIsothermal] = useState(false);
  const [isopressure, setIsopressure] = useState(false);
  const [equilibrium, setEquilibrium] = useState(false);
  const [progress, setProgress] = useState(0);

  let progressInterval: NodeJS.Timer;

  let idToken: any;
  for (const [key, value] of Object.entries(localStorage)) {
    if (key?.includes("idToken")) {
      idToken = value;
    }
  }

  function startSimulation() {
    const duration = 4; /* seconds */
    progressInterval = setInterval(
      () => setProgress((p) => p + 1),
      duration * 7
    );
    setTimeout(
      () =>
        api.solveCFD(idToken).then((resp) => {
          if (resp.status === 200) {
            const [f_v, g_v] = _.chunk(resp.data, 40 ** 2);
            setF(f_v);
            setG(g_v);
            setRan(true);
            setProgress(0);
            clearInterval(progressInterval);
          } else {
            setApiFailed(true);
          }
        }),
      duration * 1000
    );
  }

  return (
    <>
      <Header />
      <Styled.Container>
        <MathJaxContext>
          <Styled.Side>
            <Styled.ProjectName>
              <Styled.UnnamedProject>Unnamed project</Styled.UnnamedProject>
              <Styled.RenameButton title="Rename project" onClick={() => {}}>
                <Styled.RenameIcon />
              </Styled.RenameButton>
            </Styled.ProjectName>
            <div id="Project">
              <Styled.Project>
                <Styled.RunButton
                  title="Run project"
                  onClick={() => startSimulation()}
                >
                  <BiPlay />
                </Styled.RunButton>
                <Styled.Button title="Save project">
                  <BiSave />
                </Styled.Button>
                <Styled.Button title="Load project">
                  <BiUpload />
                </Styled.Button>
                <Styled.Button title="New project">
                  <BiFileBlank />
                </Styled.Button>
              </Styled.Project>
            </div>
            <Styled.SideInput>
              <Styled.Header>Equation</Styled.Header>
              <Selector
                name="Equation"
                setCurrentValue={setEquation}
                currentValue={equation}
                availableValues={[
                  "Euler",
                  "Damped Euler",
                  "Laminar Navier-Stockes",
                  "Turbulent Navier-Stockes (k-eps model)",
                ]}
                defaultValue={"Select equation"}
              />
            </Styled.SideInput>
            <Styled.SideInput>
              <Styled.Header>Dimensionality</Styled.Header>
              <Selector
                name="Dimensionality"
                setCurrentValue={setDimension}
                currentValue={dimension}
                availableValues={["1D", "2D", "3D"]}
                defaultValue={"Select dimension"}
              />
              <Styled.CInput
                type="checkbox"
                id="time"
                onChange={(e) => setEquilibrium(e.target.checked)}
              />
              <Styled.CLabel htmlFor="time">Stationary analysis</Styled.CLabel>
            </Styled.SideInput>
            <Styled.SideInput>
              <Styled.Header>Properties</Styled.Header>
              <Styled.Form>
                <Styled.CInput
                  type="checkbox"
                  name="properties"
                  id="compressible"
                />
                <Styled.CLabel htmlFor="compressible">
                  Incompressible fluid
                </Styled.CLabel>
                <br />
                <Styled.CInput
                  type="checkbox"
                  name="properties"
                  id="constant"
                  onChange={(e) => {
                    setIsopressure(e.target.checked);
                  }}
                />
                <Styled.CLabel htmlFor="constant">
                  Constant pressure
                </Styled.CLabel>
                <br />
                <Styled.CInput
                  type="checkbox"
                  name="properties"
                  id="isothermal"
                  onChange={(e) => {
                    setIsothermal(e.target.checked);
                  }}
                />
                <Styled.CLabel htmlFor="isothermal">Isothermal</Styled.CLabel>
                <br />
                <Styled.CInput
                  type="checkbox"
                  name="properties"
                  id="acceleration"
                />
                <Styled.CLabel htmlFor="acceleration">
                  External acceleration
                </Styled.CLabel>
                <br />
                <Styled.CInput type="checkbox" name="properties" id="species" />
                <Styled.CLabel htmlFor="species">Multi-species</Styled.CLabel>
              </Styled.Form>
            </Styled.SideInput>
          </Styled.Side>
          <Styled.Center>
            {(equation.includes("Euler") || equation.includes("Navier")) && (
              <Styled.Equations>
                <Styled.Formulas>
                  <>
                    <ContinuityEquation
                      dimension={dimension}
                      equilibrium={equilibrium}
                    />
                    <MomentumEquation
                      isopressure={isopressure}
                      equation={equation}
                      dimension={dimension}
                      equilibrium={equilibrium}
                    />
                    {!isothermal && (
                      <EnergyEquation
                        dimension={dimension}
                        equation={equation}
                        equilibrium={equilibrium}
                      />
                    )}
                  </>
                </Styled.Formulas>
                <Styled.Functions>
                  <Styled.Math>{e`\rho:\text{ mass density }(kg.m^{−3})`}</Styled.Math>
                  <Styled.Math>{e`\mathbf{u}:\text{ fluid velocity vector }(m.s^{−1})`}</Styled.Math>
                  {!isothermal && (
                    <Styled.Math>{e`E:\text{ energy density }(J.kg^{−1})`}</Styled.Math>
                  )}
                </Styled.Functions>
              </Styled.Equations>
            )}
            {progress !== 0 && (
              <ProgressBar
                transitionDuration="100ms"
                width="20rem"
                completed={_.min([progress, 100]) || 0}
              />
            )}
            {ran && !apiFailed && (
              <Styled.Results>
                <Styled.Graphs>
                  <Plot3D
                    data={f}
                    name="Flow velocity"
                    axes={{ x: "x (mm)", y: "t (s)", z: "u (m/s)" }}
                  />
                  <Plot3D
                    data={g}
                    name="Flow density"
                    axes={{ x: "x (mm)", y: "t (s)", z: "rho (kg/m³)" }}
                  />
                </Styled.Graphs>
                <Styled.ResultPane>
                  <h3> Technical KPIs </h3>
                  <p> Using the OVH Qaptiva </p>
                  <p style={{ margin: 0 }}> Run time: 2.1s </p>
                  <p style={{ margin: 0 }}> Mean error: 0.8% </p>
                  <p style={{ margin: 0 }}> Max error: 1.5% </p>
                  <h3 style={{ marginTop: "5em" }}>Advantage over classical</h3>
                  <p> Using an OVH box with AMD EPYC 9754 and 128G of memory</p>
                  <p style={{ margin: 0 }}> +32% precision </p>
                  <p style={{ margin: 0 }}> -11% cost </p>
                  <p style={{ margin: 0 }}> -46% energy consumption </p>
                  <h3 style={{ marginTop: "5em" }}>
                    Comparison with a classical solver
                  </h3>
                  <img
                    style={{ width: "20rem" }}
                    src="images/cfd-classical_comparison.png"
                    alt="Comparison with a classical solver"
                  />
                </Styled.ResultPane>
              </Styled.Results>
            )}

            {!ran && progress === 0 && (
              <div style={{ display: "flex", gap: "2em", marginTop: "5em" }}>
                <UploadBox name="Upload geometric profile" />
                <UploadBox name="Upload boundary conditions file" />
              </div>
            )}
          </Styled.Center>
          {equation.includes("Damped Euler") && (
            <>
              <DropDown
                setViscosity={setViscosity}
                viscosity={viscosity}
                isSet={equation.includes("Damped Euler")}
              />
            </>
          )}

          {equation.includes("Navier-Stockes") && (
            <>
              <Styled.Side>
                <Styled.SideInput>
                  <Styled.Header style={{ display: "flex" }}>
                    Viscosity stress tensor{" "}
                    <Styled.Math>{e`\Sigma`}</Styled.Math>
                  </Styled.Header>
                  <Selector
                    name="Viscosity"
                    setCurrentValue={setViscosity}
                    currentValue={viscosity}
                    availableValues={[]}
                    defaultValue={"Select viscosity"}
                  />
                </Styled.SideInput>

                <Styled.SideInput>
                  <Styled.Header style={{ display: "flex" }}>
                    Heat Flow{" "}
                    <Styled.Math
                      style={{ marginBlock: 0 }}
                    >{e`\mathbf{Q}`}</Styled.Math>
                  </Styled.Header>
                  <Selector
                    name="Viscosity"
                    setCurrentValue={setViscosity}
                    currentValue={viscosity}
                    availableValues={[]}
                    defaultValue={"Select viscosity"}
                  />
                </Styled.SideInput>
              </Styled.Side>
            </>
          )}
        </MathJaxContext>
      </Styled.Container>
    </>
  );
}

function DropDown(setViscosity: any, viscosity: any, isSet: any) {
  return (
    <Styled.Side>
      {isSet ? (
        <Styled.SideInput>
          <Styled.Header>Viscosity stress tensor</Styled.Header>
          <Selector
            name="Viscosity"
            setCurrentValue={setViscosity}
            currentValue={viscosity}
            availableValues={[]}
            defaultValue={"Select viscosity"}
          />
        </Styled.SideInput>
      ) : (
        ""
      )}
      <Styled.SideInput>
        <Styled.Header>Parameters</Styled.Header>
        <Styled.ParamInput>
          <Styled.Label htmlFor="lambda">
            <Styled.Math>{e`\lambda: `}</Styled.Math>
          </Styled.Label>
          <Styled.FInput
            name="lambda"
            coord="lambda"
            location={{ lambda: 1 }}
            disabled={false}
            setter={() => {}}
          />
        </Styled.ParamInput>
        <Styled.ParamInput>
          <Styled.Label htmlFor="t">
            <Styled.Math>{e`\mu: `}</Styled.Math>
          </Styled.Label>
          <Styled.FInput
            name="mu"
            coord="mu"
            location={{ mu: 1 }}
            disabled={false}
            setter={() => {}}
          />
        </Styled.ParamInput>
      </Styled.SideInput>
    </Styled.Side>
  );
}
export default CfdSimulation;
